<template>
  <v-container class="pa-4 text-center">
    <PageTitle text="Brainspotting" />
    <PageText :text="p1" />
    <PageText :text="p2" />
    <a href="https://brainspotting.com/about-bsp/what-is-brainspotting/">What is Brainspotting?</a>
    <br/>
    <br/>
    <PageQuote :text="q1" person="David Grand" />
  </v-container>
</template>

<script>
import PageText from '@/components/PageText.vue';
import PageTitle from '@/components/PageTitle.vue';
import PageQuote from '@/components/PageQuote.vue';

export default {
  components: { PageText, PageTitle, PageQuote },
  data: () => ({
    p1: `
      The infamous Brainspotting (BSP) slogan is: “Where you look affects how you feel.” I use BSP
      as a tool in my practice to assist clients in processing somatic or emotional experiences
      that they may struggle to resolve on their own. I assist my clients in discovering
      “brainspots” by finding reflexes in conjunction with bilateral sounds. By doing so, it is
      theorized that this assists in activating the subcortical brain and releasing stress or
      trauma. It is common for clients to report feeling less physically uncomfortable and more
      motivated following sessions. I have observed this intervention to be particularly impactful
      for individuals struggling with nightmares, specific phobias, perplexing triggers, or
      creative blocks. 
    `,
    p2: `
      For more information on Brainspotting, visit: 
    `,
    q1: `
      “Whatever the client was experiencing changed. Images and memories came more quickly.
      Emotions and body experience went deeper and moved on more rapidly and easily. Clients also
      got to observe the process while they were in it. The process was fascinating and still is.”
    `,
  }),
};
</script>
